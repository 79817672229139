import React from 'react';
import './ComingSoon.css'; // Import the CSS we’ll create below

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="content-wrapper">
        <img src="/blitz_logo.png" alt="BLITZ Logo" className="coming-soon-logo" />
        <h1 className="headline">Coming Soon</h1>
        <p className="subtext">
          The future of ridesharing is on its way. Sign up and be the first to know when we launch!
        </p>
        <a href="/" className="home-link">← Back to Home</a>
      </div>
    </div>
  );
};

export default ComingSoon;

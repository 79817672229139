// src/pages/InstaBlitzDashboard.js

import React, { useEffect, useState, useMemo } from 'react';
import {
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { db, auth } from '../firebase'; // Ensure Firebase is initialized correctly
import { useNavigate } from 'react-router-dom'; // For navigation
import './InstaBlitzDashboard.css'; // CSS file for styling

const InstaBlitzDashboard = () => {
  // Which tab is active: 'drivers' or 'payments'
  const [activeTab, setActiveTab] = useState('drivers');

  // Drivers
  const [instablitzDrivers, setInstablitzDrivers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Driver Payments
  const [driverPayments, setDriverPayments] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);

  // For selecting a driver in the Payment form
  const [selectedDriver, setSelectedDriver] = useState(null);

  // Payment form states (read-only for fare, method, rideId; admin can set status/date/memo)
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentMemo, setPaymentMemo] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('Pending');
  const [paidDate, setPaidDate] = useState('');
  const [rideId, setRideId] = useState('');

  // Error handling, search, navigation
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Payment counters
  const [pendingCount, setPendingCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);

  // ───────────────────────────────────────────────────────────
  //  Fetch Drivers (fastTrackDrivers)
  // ───────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchInstaBlitzDrivers = () => {
      try {
        const unsubscribe = onSnapshot(
          collection(db, 'fastTrackDrivers'),
          (snapshot) => {
            const drivers = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setInstablitzDrivers(drivers);
            setLoading(false);
          },
          (err) => {
            console.error('Error fetching InstaBlitz drivers:', err);
            setError('Error fetching InstaBlitz drivers.');
            setLoading(false);
          }
        );
        return () => unsubscribe();
      } catch (err) {
        console.error('Error setting up snapshot listener:', err);
        setError('Error setting up data listener.');
        setLoading(false);
      }
    };

    fetchInstaBlitzDrivers();
  }, []);

  // ───────────────────────────────────────────────────────────
  //  Fetch Payments (driverPayments)
  // ───────────────────────────────────────────────────────────
  useEffect(() => {
    if (activeTab === 'payments') {
      setPaymentLoading(true);
      const fetchDriverPayments = () => {
        try {
          const unsubscribe = onSnapshot(
            collection(db, 'driverPayments'),
            (snapshot) => {
              const payments = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              setDriverPayments(payments);
              setPaymentLoading(false);

              // Update counters
              const pending = payments.filter((p) => p.paymentStatus === 'Pending').length;
              const review = payments.filter((p) => p.paymentStatus === 'To Review').length;
              setPendingCount(pending);
              setReviewCount(review);
            },
            (err) => {
              console.error('Error fetching driver payments:', err);
              setError('Error fetching driver payments.');
              setPaymentLoading(false);
            }
          );
          return () => unsubscribe();
        } catch (err) {
          console.error('Error setting up payment snapshot listener:', err);
          setError('Error setting up payment data listener.');
          setPaymentLoading(false);
        }
      };

      fetchDriverPayments();
    }
  }, [activeTab]);

  // ───────────────────────────────────────────────────────────
  //  Driver Profiles for merging
  // ───────────────────────────────────────────────────────────
  const [driverProfiles, setDriverProfiles] = useState([]);
  useEffect(() => {
    if (activeTab === 'payments') {
      const fetchDriverProfiles = () => {
        try {
          const unsubscribe = onSnapshot(
            collection(db, 'fastTrackDrivers'),
            (snapshot) => {
              const profiles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              setDriverProfiles(profiles);
            },
            (err) => {
              console.error('Error fetching driver profiles:', err);
              setError('Error fetching driver profiles.');
            }
          );
          return () => unsubscribe();
        } catch (err) {
          console.error('Error setting up driver profiles listener:', err);
          setError('Error setting up driver profiles listener.');
        }
      };

      fetchDriverProfiles();
    }
  }, [activeTab]);

  // ───────────────────────────────────────────────────────────
  //  Merge driverPayments with driverProfiles
  // ───────────────────────────────────────────────────────────
  const mergedDriverPayments = useMemo(() => {
    return driverPayments.map((payment) => {
      const driver = driverProfiles.find((d) => d.id === payment.driverId);
      return {
        ...payment,
        driverName: driver ? driver.name : 'Unknown Driver',
        driverEmail: driver ? driver.email : 'Unknown Email',
        payoutAmount: payment.amount,
        paymentMethodChosen: payment.paymentMethod || driver?.paymentMethod || 'N/A',
        rideCompletionDate: payment.rideCompletionDate || 'N/A',
        paymentDetails: payment.memo || driver?.paymentDetails || 'No Details',
      };
    });
  }, [driverPayments, driverProfiles]);

  // ───────────────────────────────────────────────────────────
  //  Filter drivers
  // ───────────────────────────────────────────────────────────
  const filteredDrivers = useMemo(() => {
    return instablitzDrivers.filter((driver) => {
      const nameMatch = driver.name?.toLowerCase().includes(searchTerm.toLowerCase());
      const emailMatch = driver.email?.toLowerCase().includes(searchTerm.toLowerCase());
      return nameMatch || emailMatch;
    });
  }, [instablitzDrivers, searchTerm]);

  // ───────────────────────────────────────────────────────────
  //  Filter payments
  // ───────────────────────────────────────────────────────────
  const filteredPayments = useMemo(() => {
    return mergedDriverPayments.filter((payment) => {
      const driverNameMatch = payment.driverName?.toLowerCase().includes(searchTerm.toLowerCase());
      const emailMatch = payment.driverEmail?.toLowerCase().includes(searchTerm.toLowerCase());
      const paymentIdMatch = payment.paymentId?.toLowerCase().includes(searchTerm.toLowerCase());

      return driverNameMatch || emailMatch || paymentIdMatch;
    });
  }, [mergedDriverPayments, searchTerm]);

  // ───────────────────────────────────────────────────────────
  //  Deletions
  // ───────────────────────────────────────────────────────────
  const handleDeleteDriver = async (id) => {
    if (!window.confirm('Are you sure you want to delete this driver?')) return;
    try {
      await deleteDoc(doc(db, 'fastTrackDrivers', id));
    } catch (error) {
      console.error('Error deleting driver:', error);
      setError('Error deleting driver.');
    }
  };

  const handleDeletePayment = async (id) => {
    if (!window.confirm('Are you sure you want to delete this payment record?')) return;
    try {
      await deleteDoc(doc(db, 'driverPayments', id));
    } catch (error) {
      console.error('Error deleting payment:', error);
      setError('Error deleting payment.');
    }
  };

  // ───────────────────────────────────────────────────────────
  //  Logout, Admin Nav
  // ───────────────────────────────────────────────────────────
  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error logging out:', error);
        setError('Error logging out.');
      });
  };
  const goToAdminDashboard = () => {
    navigate('/admin');
  };

  // ───────────────────────────────────────────────────────────
  //  Tab change
  // ───────────────────────────────────────────────────────────
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSearchTerm('');
    setError(null);
  };

  // ───────────────────────────────────────────────────────────
  //  FAST TRACK RIDES (to link fare, rideID, paymentMethod)
  // ───────────────────────────────────────────────────────────
  const [fastTrackRides, setFastTrackRides] = useState([]);

  useEffect(() => {
    if (activeTab === 'payments') {
      const fetchRides = () => {
        try {
          const unsubscribe = onSnapshot(
            collection(db, 'fastTrackRides'),
            (snapshot) => {
              const ridesData = snapshot.docs.map((doc) => ({
                docId: doc.id,
                ...doc.data(),
              }));
              setFastTrackRides(ridesData);
            },
            (err) => {
              console.error('Error fetching fastTrackRides:', err);
              setError('Error fetching rides.');
            }
          );
          return () => unsubscribe();
        } catch (err) {
          console.error('Error setting up rides listener:', err);
          setError('Error setting up rides listener.');
        }
      };
      fetchRides();
    }
  }, [activeTab]);

  // ───────────────────────────────────────────────────────────
  //  Handle "Add Payment"
  // ───────────────────────────────────────────────────────────
  const handleAddPayment = async (e) => {
    e.preventDefault();
    if (!selectedDriver) {
      alert('Please select a driver first.');
      return;
    }
    try {
      const paymentId = `PAY-${Date.now()}`;
      const paymentData = {
        paymentId,
        driverId: selectedDriver.id,
        driverEmail: selectedDriver.email || 'No Email',
        driverFirstName: selectedDriver.firstName || '',
        driverLastName: selectedDriver.lastName || '',
        rideID: rideId,
        paymentMethod: paymentMethod || 'N/A',
        paymentDetails: paymentMemo.trim() || '',
        amount: parseFloat(paymentAmount) || 0,
        paymentStatus,
        paymentDate: new Date().toISOString(),
        paidDate:
          paymentStatus === 'Paid' && paidDate
            ? new Date(paidDate).toISOString()
            : null,
      };

      await setDoc(doc(db, 'driverPayments', paymentId), paymentData);
      alert('Payment logged successfully!');

      // Clear the form
      setPaymentAmount('');
      setPaymentMethod('');
      setPaymentMemo('');
      setPaymentStatus('Pending');
      setPaidDate('');
      setSelectedDriver(null);
      setRideId('');
    } catch (error) {
      console.error('Error adding payment:', error);
      setError('Error adding payment.');
    }
  };

  // ───────────────────────────────────────────────────────────
  //  Update Payment Status
  // ───────────────────────────────────────────────────────────
  const handleUpdatePaymentStatus = async (paymentId, newStatus, newPaidDate = null) => {
    try {
      const paymentRef = doc(db, 'driverPayments', paymentId);
      const updateData = { paymentStatus: newStatus };

      if (newStatus === 'Paid' && newPaidDate) {
        updateData.paidDate = new Date(newPaidDate).toISOString();
      } else if (newStatus !== 'Paid') {
        updateData.paidDate = null;
      }

      await updateDoc(paymentRef, updateData);
    } catch (error) {
      console.error('Error updating payment status:', error);
      setError('Error updating payment status.');
    }
  };

  // For capturing the "Paid Date" inline
  const [dateInputs, setDateInputs] = useState({});

  return (
    <div className="instablitz-dashboard">
      <header className="dashboard-header">
        <h2>InstaBlitz Dashboard</h2>
        <div className="header-buttons">
          <button onClick={goToAdminDashboard} className="back-to-admin-btn">
            Back to Admin Dashboard
          </button>
          <button onClick={handleLogout} className="logout-btn">
            Logout
          </button>
        </div>
      </header>

      {/* Tabs */}
      <div className="tabs">
        <button
          className={activeTab === 'drivers' ? 'active' : ''}
          onClick={() => handleTabChange('drivers')}
        >
          Drivers
        </button>
        <button
          className={activeTab === 'payments' ? 'active' : ''}
          onClick={() => handleTabChange('payments')}
        >
          Payments
        </button>
      </div>

      {/* Payment counters */}
      {activeTab === 'payments' && (
        <div className="payment-counters">
          <div className="counter">
            Pending Payments: <strong>{pendingCount}</strong>
          </div>
          <div className="counter">
            To Review: <strong>{reviewCount}</strong>
          </div>
        </div>
      )}

      {/* Search bar */}
      <div className="search-container">
        <input
          type="text"
          placeholder={
            activeTab === 'drivers'
              ? 'Search drivers by name or email...'
              : 'Search payments by name, email, or payment ID...'
          }
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      {/* Error display */}
      {error && <div className="error">{error}</div>}

      {/* Drivers Tab */}
      {activeTab === 'drivers' && (
        <>
          {loading && <div className="loading">Loading InstaBlitz driver applications...</div>}

          {!loading && (
            <>
              {filteredDrivers.length === 0 ? (
                <div className="no-drivers">No drivers found for InstaBlitz.</div>
              ) : (
                <div className="table-container">
                  <table className="drivers-table">
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>License Verified</th>
                        <th>License Image</th>
                        <th>Onboarded</th>
                        <th>Onboarding Time</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDrivers.map((driver) => (
                        <tr key={driver.id}>
                          <td>
                            <input
                              type="radio"
                              name="selectedDriver"
                              checked={selectedDriver?.id === driver.id}
                              onChange={() => {
                                setSelectedDriver(driver);
                                // Also fill in from driver doc if needed
                                setPaymentMemo(driver.paymentDetails || '');
                                setPaymentMethod(driver.paymentMethod || '');
                                setRideId(driver.rideID || '');
                              }}
                            />
                          </td>
                          <td>{driver.name || 'No Name'}</td>
                          <td>{driver.email || 'No Email'}</td>
                          <td>{driver.phone || 'No Phone'}</td>
                          <td>{driver.isLicenseVerified ? 'Yes' : 'No'}</td>
                          <td>
                            {driver.licenseFileUrl ? (
                              <a href={driver.licenseFileUrl} target="_blank" rel="noopener noreferrer">
                                View License
                              </a>
                            ) : (
                              'No Image'
                            )}
                          </td>
                          <td>{driver.isOnboarded ? 'Yes' : 'No'}</td>
                          <td>
                            {driver.onboardTimestamp
                              ? driver.onboardTimestamp.toDate().toLocaleString()
                              : 'N/A'}
                          </td>
                          <td>
                            <button
                              onClick={() => handleDeleteDriver(driver.id)}
                              className="delete-btn"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* Payments Tab */}
      {activeTab === 'payments' && (
        <>
          {paymentLoading && <div className="loading">Loading payment records...</div>}

          {!paymentLoading && (
            <>
              {/* Payment Form */}
              <div className="payment-form">
                <h3>Add Payment</h3>
                <form onSubmit={handleAddPayment}>
                  <div className="form-group">
                    <label>Select Driver:</label>
                    <select
                      value={selectedDriver?.id || ''}
                      onChange={(e) => {
                        // 1) set the driver
                        const driver = instablitzDrivers.find((d) => d.id === e.target.value);
                        setSelectedDriver(driver || null);
                        // 2) Fill from driver doc
                        if (driver) {
                          setPaymentMemo(driver.paymentDetails || '');
                          setPaymentMethod(driver.paymentMethod || '');
                          setRideId(driver.rideID || '');
                        }
                      }}
                      required
                    >
                      <option value="" disabled>-- Select Driver --</option>
                      {instablitzDrivers.map((driver) => (
                        <option key={driver.id} value={driver.id}>
                          {driver.name || 'No Name'} - {driver.email || 'No Email'}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* We'll do the actual hooking with fastTrackRides in RideAutoPopulate */}
                  {/* RideID read-only */}
                  <div className="form-group">
                    <label>Ride ID (Read-Only):</label>
                    <input
                      type="text"
                      value={rideId}
                      onChange={(e) => setRideId(e.target.value)}
                      readOnly
                    />
                  </div>

                  {/* Payment Amount read-only */}
                  <div className="form-group">
                    <label>Fare (Read-Only):</label>
                    <input
                      type="text"
                      value={paymentAmount}
                      readOnly
                    />
                  </div>

                  {/* Payment Method read-only (from rides or driver doc) */}
                  <div className="form-group">
                    <label>Payment Method (Read-Only):</label>
                    <input
                      type="text"
                      value={paymentMethod}
                      readOnly
                    />
                  </div>

                  {/* Payment Status (admin can set) */}
                  <div className="form-group">
                    <label>Payment Status:</label>
                    <select
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                      required
                    >
                      <option value="Pending">Pending</option>
                      <option value="Paid">Paid</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  </div>

                  {/* If Paid, require date */}
                  {paymentStatus === 'Paid' && (
                    <div className="form-group">
                      <label>Paid Date:</label>
                      <input
                        type="date"
                        value={paidDate}
                        onChange={(e) => setPaidDate(e.target.value)}
                        required
                      />
                    </div>
                  )}

                  {/* Payment Memo can be adjusted */}
                  <div className="form-group">
                    <label>Memo (Optional):</label>
                    <input
                      type="text"
                      value={paymentMemo}
                      onChange={(e) => setPaymentMemo(e.target.value)}
                      placeholder="Additional notes"
                    />
                  </div>

                  <button type="submit" className="add-payment-btn">
                    Add Payment
                  </button>
                </form>
              </div>

              {/* 
                Once a driver is selected, let's auto-find the matching ride 
                in fastTrackRides. That sets paymentAmount, paymentMethod, rideId.
              */}
              {selectedDriver && (
                <RideAutoPopulate
                  selectedDriver={selectedDriver}
                  fastTrackRides={fastTrackRides}
                  setPaymentAmount={setPaymentAmount}
                  setPaymentMethod={setPaymentMethod}
                  setRideId={setRideId}
                />
              )}

              {/* Payment Records */}
              {filteredPayments.length === 0 ? (
                <div className="no-payments">No payment records found.</div>
              ) : (
                <div className="table-container">
                  <table className="payments-table">
                    <thead>
                      <tr>
                        <th>Payment ID</th>
                        <th>Driver Name</th>
                        <th>Email</th>
                        <th>Payout Amount</th>
                        <th>Payment Method</th>
                        <th>Status</th>
                        <th>Payment Date</th>
                        <th>Ride Completion Date</th>
                        <th>Payment Details</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredPayments.map((payment) => (
                        <tr key={payment.id}>
                          <td>{payment.paymentId}</td>
                          <td>{payment.driverName}</td>
                          <td>{payment.driverEmail}</td>
                          <td>${payment.payoutAmount.toFixed(2)}</td>
                          <td>{payment.paymentMethodChosen}</td>
                          <td>
                            <span
                              className={`status-badge ${payment.paymentStatus.toLowerCase()}`}
                            >
                              {payment.paymentStatus}
                            </span>
                          </td>
                          <td>
                            {new Date(payment.paymentDate).toLocaleString()}
                          </td>
                          <td>
                            {payment.rideCompletionDate !== 'N/A'
                              ? new Date(payment.rideCompletionDate).toLocaleDateString()
                              : 'N/A'}
                          </td>
                          <td>{payment.paymentDetails}</td>
                          <td>
                            {/* Status Update actions */}
                            <div className="status-actions">
                              <select
                                value={payment.paymentStatus}
                                onChange={(e) => {
                                  const newStatus = e.target.value;
                                  if (newStatus === 'Paid') {
                                    // show date input
                                    setDateInputs((prev) => ({
                                      ...prev,
                                      [payment.id]: '',
                                    }));
                                  } else {
                                    // remove date input
                                    setDateInputs((prev) => {
                                      const newState = { ...prev };
                                      delete newState[payment.id];
                                      return newState;
                                    });
                                    handleUpdatePaymentStatus(payment.id, newStatus);
                                  }
                                }}
                              >
                                <option value="Pending">Pending</option>
                                <option value="Paid">Paid</option>
                                <option value="Rejected">Rejected</option>
                              </select>

                              {/* If user picks 'Paid', require date */}
                              {dateInputs.hasOwnProperty(payment.id) && (
                                <div className="date-picker-container">
                                  <input
                                    type="date"
                                    value={dateInputs[payment.id]}
                                    onChange={(e) =>
                                      setDateInputs((prev) => ({
                                        ...prev,
                                        [payment.id]: e.target.value,
                                      }))
                                    }
                                    required
                                  />
                                  <button
                                    onClick={() => {
                                      if (dateInputs[payment.id]) {
                                        handleUpdatePaymentStatus(
                                          payment.id,
                                          'Paid',
                                          dateInputs[payment.id]
                                        );
                                        setDateInputs((prev) => {
                                          const newState = { ...prev };
                                          delete newState[payment.id];
                                          return newState;
                                        });
                                      } else {
                                        alert('Please select a paid date.');
                                      }
                                    }}
                                    className="mark-paid-btn"
                                  >
                                    Confirm Paid
                                  </button>
                                </div>
                              )}
                            </div>

                            {/* Delete Payment */}
                            <button
                              onClick={() => handleDeletePayment(payment.id)}
                              className="delete-btn"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InstaBlitzDashboard;

/**
 * Helper: finds the correct ride doc in "fastTrackRides"
 * and populates paymentAmount (fare), paymentMethod (type),
 * and rideId (docId or rideID) as read-only fields.
 */
function RideAutoPopulate({
  selectedDriver,
  fastTrackRides,
  setPaymentAmount,
  setPaymentMethod,
  setRideId,
}) {
  useEffect(() => {
    if (!selectedDriver) return;

    // 1) Attempt to match by "driverID" if the ride doc has "driverID"
    let matchingRide = fastTrackRides.find(
      (ride) => ride.driverID === selectedDriver.id
    );

    // 2) If none found by driverID, try matching by "rideID" if driver has one
    if (!matchingRide && selectedDriver.rideID) {
      matchingRide = fastTrackRides.find((ride) => ride.docId === selectedDriver.rideID 
        || ride.rideID === selectedDriver.rideID);
    }

    if (matchingRide) {
      // Set fare
      if (matchingRide.fare != null) {
        setPaymentAmount(String(matchingRide.fare));
      } else {
        setPaymentAmount('');
      }

      // If there's a 'paymentMethod' map with 'type'
      if (matchingRide.paymentMethod?.type) {
        setPaymentMethod(matchingRide.paymentMethod.type);
      } else {
        setPaymentMethod('');
      }

      // The ride's ID
      if (matchingRide.docId) {
        setRideId(matchingRide.docId);
      } else if (matchingRide.rideID) {
        setRideId(matchingRide.rideID);
      } else {
        setRideId('');
      }
    } else {
      // If no matching ride was found, clear them
      setPaymentAmount('');
      setPaymentMethod('');
      setRideId('');
    }
  }, [selectedDriver, fastTrackRides, setPaymentAmount, setPaymentMethod, setRideId]);

  return null;
}

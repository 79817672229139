import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OnePager from './pages/OnePager';
import LoginScreen from './pages/LoginScreen';
import RegisterScreen from './pages/RegisterScreen';
import DriverOnboarding from './pages/DriverOnboarding';
import AdminDashboard from './pages/AdminDashboard';
import OnboardingPage from './pages/OnboardingPage';
import TcInstaBlitzDriver from './pages/TcInstaBlitzDriver'; // Terms and conditions page for InstaBlitz
import ToSPage from './pages/ToSPage'; // Terms of Service page for Blitz
import InstaBlitzDashboard from './pages/InstaBlitzDashboard'; // InstaBlitz dashboard
import ProtectedRoute from './components/ProtectedRoute'; // Protects admin routes
import DriverNavigationPage from './pages/DriverNavigationPage'; 
import RideCompletionAndSummary from './pages/RideCompletionAndSummary';
import ComingSoon from './pages/ComingSoon'; 

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<OnePager />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/driver-onboarding" element={<DriverOnboarding />} />
          <Route path="/onboardingpage" element={<OnboardingPage />} />
          <Route path="/drivernavigationpage" element={<DriverNavigationPage />} />
          <Route path="/ridecompletionandsummary" element={<RideCompletionAndSummary />} />

          {/* Correct Coming Soon route */}
          <Route path="/rider-coming-soon" element={<ComingSoon />} />

          {/* Terms and Conditions for InstaBlitz */}
          <Route path="/tcinstablitzdriver" element={<TcInstaBlitzDriver />} />

          {/* New Terms of Service page for Blitz */}
          <Route path="/tos" element={<ToSPage />} />

          {/* Protected Routes for Admin and InstaBlitz Dashboard */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/instablitz"
            element={
              <ProtectedRoute>
                <InstaBlitzDashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

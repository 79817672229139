// src/pages/DriverNavigationPage.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { db } from '../firebase'; // Ensure this path is correct
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import './DriverNavigationPage.css'; // Ensure this CSS file exists

// Helper function to calculate driver's cut
const calculateDriverCut = (fare) => {
  const commissionRate = 0.2; // 20% commission
  if (typeof fare !== 'number' || isNaN(fare)) {
    return '0.00';
  }
  return (fare * (1 - commissionRate)).toFixed(2);
};

// Main Component
const DriverNavigationPage = () => {
  // We will parse the rideID from the query params instead of using driverEmail
  const location = useLocation();
  const [rideID, setRideID] = useState(null);

  const [ride, setRide] = useState(null);
  const [loading, setLoading] = useState(false); // Start with false to display the page
  const [error, setError] = useState(null);

  // We track ride status so we can disable the Complete button if not in progress
  const [rideStatus, setRideStatus] = useState('N/A');

  useEffect(() => {
    // Parse the rideID from the URL, e.g. ?rideID=ABC123
    const queryParams = new URLSearchParams(location.search);
    const idFromURL = queryParams.get('rideID');
    if (!idFromURL) {
      setError('No rideID provided in the URL.');
      return;
    }
    setRideID(idFromURL);
  }, [location.search]);

  useEffect(() => {
    let isMounted = true; // Prevent state updates if the component is unmounted

    const fetchRideData = async () => {
      if (!rideID) return; // If no rideID yet, skip
      setLoading(true);

      try {
        // Fetch the ride document in fastTrackRides by rideID
        const rideRef = doc(db, 'fastTrackRides', rideID);
        const rideDocSnap = await getDoc(rideRef);

        if (!rideDocSnap.exists()) {
          if (isMounted) {
            setError('Ride not found.');
            setLoading(false);
          }
          return;
        }

        const rideData = rideDocSnap.data();

        // ADDED: Log the current ride status to verify it's not already "In Progress"
        console.log('Fetched rideData.rideStatus:', rideData.rideStatus); // ADDED

        // Update local state with the fetched doc
        if (isMounted) {
          setRide(rideData);
          setRideStatus(rideData.rideStatus || 'N/A');
        }

        // Only update to "In Progress" if it's not already
        if (isMounted && rideData.rideStatus !== 'In Progress') {
          console.log(
            `Updating ride ${rideID} to "In Progress" (currently "${rideData.rideStatus}")...`
          );

          await updateDoc(rideRef, {
            rideStatus: 'In Progress',
            updatedAt: serverTimestamp(),
          });

          console.log(`Ride ${rideID} successfully updated to "In Progress".`);

          // Reflect this change in our local component state as well
          if (isMounted) {
            setRideStatus('In Progress');
            setRide((prev) => ({
              ...prev,
              rideStatus: 'In Progress',
            }));
          }
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching ride data:', err);
        if (isMounted) {
          setError('Failed to fetch ride data.');
          setLoading(false);
        }
      }
    };

    fetchRideData();

    return () => {
      isMounted = false;
    };
  }, [rideID]);

  // Optional real-time listener (commented out):
  // useEffect(() => {
  //   if (!rideID) return;
  //   const unsub = onSnapshot(doc(db, 'fastTrackRides', rideID), (snapshot) => {
  //     if (snapshot.exists()) {
  //       const updatedRide = snapshot.data();
  //       setRide(updatedRide);
  //       setRideStatus(updatedRide.rideStatus || 'N/A');
  //     }
  //   });
  //   return () => unsub();
  // }, [rideID]);

  // Function to generate navigation URL (Waze fallback to Google Maps)
  const generateNavigationURL = () => {
    if (!ride || !ride.dropoffLocation) {
      alert('Dropoff location is not available.');
      return;
    }

    const { latitude, longitude } = ride.dropoffLocation;

    if (
      typeof latitude !== 'number' ||
      typeof longitude !== 'number' ||
      isNaN(latitude) ||
      isNaN(longitude)
    ) {
      alert('Invalid dropoff location coordinates.');
      return;
    }

    // Waze URL Scheme
    const wazeURL = `waze://?ll=${latitude},${longitude}&navigate=yes`;

    // Google Maps URL
    const googleMapsURL = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`;

    // Attempt to open Waze first
    window.location.href = wazeURL;

    // Fallback to Google Maps after a short delay if Waze isn't installed
    setTimeout(() => {
      window.open(googleMapsURL, '_blank');
    }, 2000);
  };

  // Function to mark ride as completed
  const completeRide = async () => {
    if (!rideID) {
      alert('No rideID to complete.');
      return;
    }

    const confirmCompletion = window.confirm(
      'Are you sure you want to mark this ride as completed?'
    );
    if (!confirmCompletion) return;

    try {
      // Update ride status in Firestore
      const rideRef = doc(db, 'fastTrackRides', rideID);
      await updateDoc(rideRef, {
        rideStatus: 'Completed',
        completedTimestamp: serverTimestamp(),
      });

      alert('Ride marked as completed.');
      // Reload or do something else to reflect this state
      window.location.reload();
    } catch (err) {
      console.error('Error completing ride:', err);
      alert('Failed to complete the ride. Please try again.');
    }
  };

  // Destructure ride fields
  const {
    pickupLocation = { address: 'N/A' },
    dropoffLocation = { address: 'N/A' },
    fare = 0,
    rideTimestamp = null,
    passengerName = 'N/A', // Read passenger name directly from ride doc
  } = ride || {};

  return (
    <div className="driver-navigation-container">
      {/* Blitz Logo */}
      <img src="/blitz_logo.png" alt="Blitz Logo" className="blitz-logo" />

      <h2>Ride Details</h2>

      {/* Error and Loading UI */}
      {error && <div className="error-message">Error: {error}</div>}
      {loading && <div className="loading-message">Loading...</div>}

      {/* Main Content */}
      <div className="main-content">
        {/* Pickup and Dropoff Section */}
        <div className="pickup-dropoff-container">
          <div className="location-row">
            <div className="location-icon">
              <div className="circle"></div>
              <div className="rhombus"></div>
            </div>
            <div className="location-details">
              <p>
                <span>Pickup:</span> {pickupLocation.address}
              </p>
              <p>
                <span>Dropoff:</span> {dropoffLocation.address}
              </p>
            </div>
          </div>
        </div>

        {/* Ride Details Section */}
        <div className="ride-details">
          <p>
            <span>Passenger:</span> {passengerName}
          </p>
          <p>
            <span>Fare:</span>{' '}
            {fare !== undefined ? `$${fare.toFixed(2)}` : 'N/A'}
          </p>
          <p>
            <span>Your Cut:</span>{' '}
            {fare !== undefined ? `$${calculateDriverCut(fare)}` : 'N/A'}
          </p>
          <p>
            <span>Ride Status:</span> {rideStatus}
          </p>
          <p>
            <span>Ride Requested At:</span>{' '}
            {rideTimestamp
              ? new Date(rideTimestamp.seconds * 1000).toLocaleString()
              : 'N/A'}
          </p>
        </div>

        {/* Buttons */}
        <div className="button-container">
          <button
            onClick={generateNavigationURL}
            className="navigate-button"
            disabled={!ride || !dropoffLocation}
          >
            Navigate to Dropoff
          </button>
          <button
            onClick={completeRide}
            className="complete-ride-button"
            disabled={!ride || rideStatus === 'Completed'}
          >
            Complete Ride
          </button>
        </div>
      </div>
    </div>
  );
};

export default DriverNavigationPage;

// src/pages/OnboardingPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { auth, db, storage } from '../firebase';
import {
  collection,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import './Onboarding.css';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { TextractClient, AnalyzeDocumentCommand } from '@aws-sdk/client-textract';

// AWS configuration (ensure these are set in your environment variables)
const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

// Check if AWS credentials are available
if (!AWS_REGION || !AWS_ACCESS_KEY_ID || !AWS_SECRET_ACCESS_KEY) {
  console.error('AWS configuration is missing. Please check your environment variables.');
}

// Initialize AWS Textract Client
const textractClient = new TextractClient({
  region: AWS_REGION,
  credentials: {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  },
});

// Helper function to convert file to base64
const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]); // Base64 string
    reader.onerror = (error) => reject(error);
  });
};

// Helper function to extract expiration date from text
const extractExpirationDate = (text) => {
  const lines = text.split('\n');
  let expDate = null;

  for (let line of lines) {
    line = line.trim();
    // Match patterns for expiration labels, considering different placements and abbreviations
    let expMatch = line.match(
      /(EXP|EXPIRES|EXPIRATION|EXPIRATION DATE|VALID UNTIL|VALID THRU|THRU|VALID TO|VALIDITY).*?(\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}|\d{4})/i
    );
    if (expMatch) {
      expDate = expMatch[2]; // Capture the expiration date
      break;
    }
    // Match date formats directly, allowing for different separators and lengths
    let dateMatch = line.match(/(\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}|\d{1,2}[\/\-]\d{4}|\d{4})/);
    if (dateMatch) {
      expDate = dateMatch[1];
      break;
    }
  }

  if (expDate) {
    let parsedDate;
    if (/^\d{4}$/.test(expDate)) {
      parsedDate = new Date(`${expDate}-12-31`); // YYYY format
    } else if (/^\d{1,2}[\/\-]\d{4}$/.test(expDate)) {
      // MM/YYYY format
      const [month, year] = expDate.split(/[\/\-]/);
      parsedDate = new Date(`${year}-${month}-01`);
    } else if (/^\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}$/.test(expDate)) {
      // MM/DD/YYYY or MM/DD/YY format
      const [month, day, year] = expDate.split(/[\/\-]/);
      const fullYear = year.length === 2 ? '20' + year : year;
      parsedDate = new Date(`${fullYear}-${month}-${day}`);
    } else {
      parsedDate = new Date(expDate);
    }
    return !isNaN(parsedDate.getTime()) ? parsedDate : null;
  }
  return null;
};

// Helper function to match names in different orders
const nameMatchesInDifferentOrder = (licenseText, inputName) => {
  const licenseNames = licenseText.toLowerCase().split(/\s+/);
  const inputNames = inputName.toLowerCase().split(/\s+/);
  return inputNames.every((name) => licenseNames.includes(name));
};

// Function to analyze document using AWS Textract
const analyzeDocument = async (file) => {
  const base64File = await toBase64(file);
  const params = {
    Document: {
      Bytes: Buffer.from(base64File, 'base64'),
    },
    FeatureTypes: ['FORMS', 'TABLES'], // Added 'TABLES' to improve detection of structured data
  };

  try {
    const command = new AnalyzeDocumentCommand(params);
    const response = await textractClient.send(command);
    return response;
  } catch (error) {
    console.error('Error analyzing document:', error);
    throw new Error(`Error analyzing document: ${error.message}`);
  }
};

// Phone number formatting
const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  const cleaned = ('' + phone).replace(/\D/g, '');
  if (cleaned.startsWith('1') && cleaned.length === 11) {
    return `+${cleaned}`;
  } else if (cleaned.length === 10) {
    return `+1${cleaned}`;
  } else if (phone.startsWith('+')) {
    return phone;
  } else {
    return `+${cleaned}`;
  }
};

// Function to upload files to Firebase Storage
const uploadToStorage = async (file, folder) => {
  try {
    const storageRef = ref(storage, `${folder}/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error('Error uploading file to storage:', error);
    throw error;
  }
};

// Main Onboarding Form Component
const OnboardingForm = () => {
  // State Variables
  const [rideID, setRideID] = useState('');
  const [pickupLat, setPickupLat] = useState('');
  const [pickupLng, setPickupLng] = useState('');
  const [dropoffLat, setDropoffLat] = useState('');
  const [dropoffLng, setDropoffLng] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [licenseFile, setLicenseFile] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentDetails, setPaymentDetails] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Parse URL parameters to extract QR code data
    const queryParams = new URLSearchParams(location.search);
    const rideIDParam = queryParams.get('rideID');
    const pickupLatParam = queryParams.get('pickupLat');
    const pickupLngParam = queryParams.get('pickupLng');
    const dropoffLatParam = queryParams.get('dropoffLat');
    const dropoffLngParam = queryParams.get('dropoffLng');

    if (rideIDParam) {
      setRideID(rideIDParam);
      setPickupLat(pickupLatParam || '');
      setPickupLng(pickupLngParam || '');
      setDropoffLat(dropoffLatParam || '');
      setDropoffLng(dropoffLngParam || '');
    } else {
      alert('Invalid ride information. Please ensure you have scanned a valid QR code.');
      navigate('/'); // Redirect to home if rideID is missing
    }

    // Initialize reCAPTCHA once when the component mounts
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible', // Use 'invisible' reCAPTCHA to minimize user interaction
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log('reCAPTCHA solved');
          },
          'expired-callback': () => {
            console.log('reCAPTCHA expired');
          },
        },
        auth
      );

      window.recaptchaVerifier.render().catch((error) => {
        console.error('Error rendering reCAPTCHA:', error);
        alert('Error initializing reCAPTCHA. Please try again.');
      });
    }
  }, [location.search, navigate]);

  // Function to send SMS verification code
  const sendVerificationCode = async () => {
    if (!phone) {
      alert('Please enter a valid phone number.');
      return;
    }

    const formattedPhone = formatPhoneNumber(phone);

    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedPhone,
        appVerifier
      );
      window.confirmationResult = confirmationResult;
      alert('Verification code sent.');
    } catch (error) {
      console.error('Error sending verification code:', error);
      alert('Error sending verification code. Please try again.');
      // Reset reCAPTCHA in case of error
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    }
  };

  // Handle phone verification
  const verifyPhoneNumber = async () => {
    if (!verificationCode) {
      alert('Please enter the verification code.');
      return;
    }

    try {
      const confirmationResult = window.confirmationResult;
      await confirmationResult.confirm(verificationCode);
      setIsPhoneVerified(true);
      alert('Phone number verified!');
      console.log('Phone number verified successfully.');
    } catch (error) {
      console.error('Error verifying code:', error);
      alert('Invalid code. Please try again.');
    }
  };

  // Extract license details using AWS Textract
  const extractLicenseDetails = async (file) => {
    setLoading(true);
    try {
      const textractData = await analyzeDocument(file);
      const text = textractData.Blocks.filter((block) => block.BlockType === 'LINE')
        .map((block) => block.Text)
        .join('\n');

      const expirationDate = extractExpirationDate(text);
      const nameMatches = nameMatchesInDifferentOrder(text, name);

      if (nameMatches && expirationDate) {
        const currentDate = new Date();
        if (expirationDate >= currentDate) {
          alert('Driver’s license verification successful.');
          return true;
        } else {
          alert('Driver’s license is expired.');
          return false;
        }
      } else {
        alert('Name does not match the driver’s license.');
        return false;
      }
    } catch (error) {
      console.error('Error analyzing license:', error);
      alert('Failed to analyze the driver’s license. Please try again.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setLoading(true);

    try {
      // Validation
      if (!isPhoneVerified) {
        alert('Please verify your phone number.');
        throw new Error('Phone number not verified.');
      }

      if (!termsAccepted) {
        alert('Please accept the Terms and Conditions.');
        throw new Error('Terms not accepted.');
      }

      if (!paymentMethod || !paymentDetails) {
        alert('Please select a payment method and provide the required details.');
        throw new Error('Payment method/details missing.');
      }

      if (!licenseFile) {
        alert('Please upload your driver’s license.');
        throw new Error('Driver’s license not uploaded.');
      }

      // Extract and validate license details
      const isLicenseValid = await extractLicenseDetails(licenseFile);
      if (!isLicenseValid) {
        throw new Error('Driver’s license validation failed.');
      }

      // Generate unique payment ID
      const paymentID = uuidv4();

      // Upload license file
      const licenseFileUrl = await uploadToStorage(licenseFile, 'licenses');
      console.log('License uploaded to:', licenseFileUrl);
      alert('Driver’s license uploaded successfully.');

      // Prepare data to submit to Firestore
      const driverData = {
        name,
        email,
        phone,
        licenseFileUrl,
        paymentMethod,
        paymentDetails,
        paymentID,
        rideID, // Associate driver with the ride
        isOnboarded: true,
        onboardTimestamp: serverTimestamp(),
      };
      console.log('Submitting driver data to Firestore:', driverData);
      alert('Submitting your information. Please wait...');

      // Add driver details to Firestore
      await addDoc(collection(db, 'fastTrackDrivers'), driverData);
      alert('Onboarding completed successfully!');

      // Navigate to DriverNavigationPage with rideID as URL parameter
      // We use the same rideID that was set from the QR link, ensuring the driver sees the correct ride doc.
      navigate(`/drivernavigationpage?rideID=${rideID}`);
    } catch (error) {
      console.error('Error during onboarding:', error);
      alert(`Error during onboarding: ${error.message}`);
    } finally {
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  // Open file selector for scanning driver's license
  const openFileSelectorForLicense = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    // Removed 'capture' attribute for better compatibility
    // fileInput.capture = 'environment';

    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        setLicenseFile(file);
        alert('Driver’s license photo selected!');
      } else {
        alert('No file selected.');
      }
    };
    fileInput.click();
  };

  return (
    <>
      <form className="onboarding-form" onSubmit={handleSubmit}>
        <h2>Driver Onboarding</h2>

        {/* Name Input */}
        <div className="form-section">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name as it appears on driver's license"
            required
          />
        </div>

        {/* Email Input */}
        <div className="form-section">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        {/* Phone Verification */}
        <div className="form-section">
          <label htmlFor="phone">Phone Number</label>
          <div className="phone-verification-container">
            <input
              id="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              placeholder="+1XXXXXXXXXX"
            />
            <button
              type="button"
              className="send-code-btn subtle-btn"
              onClick={sendVerificationCode}
              disabled={isSubmitting}
            >
              Send Code
            </button>
          </div>
          <div className="phone-verification-container">
            <input
              type="text"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              disabled={isSubmitting}
            />
            <button
              type="button"
              className="verify-btn subtle-btn"
              onClick={verifyPhoneNumber}
              disabled={isSubmitting}
            >
              Verify Phone
            </button>
          </div>
          {/* reCAPTCHA container positioned next to the verify button */}
          <div id="recaptcha-container" className="recaptcha-container"></div>
          {/* Instructions for reCAPTCHA */}
          {!isPhoneVerified && (
            <p className="recaptcha-instruction">
              Please complete the reCAPTCHA challenge if prompted.
            </p>
          )}
        </div>

        {/* Payment Method Selection */}
        <div className="form-section">
          <label>Preferred Payment Method</label>
          <div className="payment-methods">
            <label>
              <input
                type="radio"
                name="paymentMethod"
                value="Venmo"
                checked={paymentMethod === 'Venmo'}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              Venmo
            </label>
            <label>
              <input
                type="radio"
                name="paymentMethod"
                value="PayPal"
                checked={paymentMethod === 'PayPal'}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              PayPal
            </label>
            <label>
              <input
                type="radio"
                name="paymentMethod"
                value="Zelle"
                checked={paymentMethod === 'Zelle'}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              Zelle
            </label>
          </div>
          {paymentMethod && (
            <input
              type="text"
              placeholder={`Enter your ${paymentMethod} account details`}
              value={paymentDetails}
              onChange={(e) => setPaymentDetails(e.target.value)}
              required
            />
          )}
        </div>

        {/* Scan Driver's License */}
        <div className="form-section">
          <label>Scan Driver's License</label>
          <button
            type="button"
            className="scan-license-btn subtle-btn"
            onClick={openFileSelectorForLicense}
            disabled={isSubmitting}
          >
            Upload License
          </button>
          {licenseFile && <p>Driver's license image selected.</p>}
        </div>

        {/* Terms and Conditions */}
        <div className="form-section terms-link">
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
          />
          <label htmlFor="terms">
            I have reviewed and accept the{' '}
            <a href="/tcinstablitzdriver" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
            .
          </label>
        </div>

        {/* Loading Indicator */}
        {loading && (
          <div className="loading-bar">
            <div className="progress"></div>
            Processing...
          </div>
        )}

        {/* Submit Button */}
        {isPhoneVerified && termsAccepted && paymentMethod && paymentDetails && (
          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Complete Onboarding'}
          </button>
        )}
      </form>
    </>
  );
};

const OnboardingPage = () => {
  return <OnboardingForm />;
};

export default OnboardingPage;
